import React from "react"
import styles from "../View/view.module.css"
import { Link } from 'gatsby'
import { globalHistory } from '@reach/router'

class Breadcrumb extends React.Component {

  render(){
    const links = [];
    const path = globalHistory.location.pathname + globalHistory.location.search
    links.push(<li key="home"><Link to="/">HOME</Link></li>)
    if(path !== '/'){
      if(this.props.title === 'YOUR PROFILE'){
        links.push(<li key="profile"><Link to="/profile">USER ACCOUNT</Link></li>)
      }else{
        links.push(<li key="profile"><Link to="/profile">USER ACCOUNT</Link></li>)
        links.push(<li key="current-path"><Link to={path}>{this.props.title}</Link></li>)
      }
    }
    return(
      <ul className={styles[`breadcrumb`]}>
        {links}
      </ul>
    )
  }
}

export default Breadcrumb
