import React from "react"
import PropTypes from "prop-types"
import styles from "./view.module.css"
import Breadcrumb from "../Breadcrumb"

const View = ({ title, children }) => (
  <div className={styles[`view__wrapper`]}>
  <section className={styles.view}>
  <div className="row">
  <div className="col-xs-12">
    <Breadcrumb
      title={title}
    />
    <h1 className={styles[`view__heading`]}>{title}</h1>
  </div>
  </div>
  <div className="row">
  <div className="col-xs-12">
    {children}
  </div>
  </div>
  </section>
  </div>
)

View.propTypes = {
  title: PropTypes.string.isRequired,
}

export default View
