import React from 'react'
import Layout from '../components/Layout'
import Form from '../components/Form/register'
import View from '../components/View'
import { navigate, globalHistory } from '@reach/router'
import { handleRegister, isLoggedIn } from '../utils/auth'
import { parseQuery } from '../utils/query'

class Register extends React.Component {
  state = {
    username: ``,
    password: ``,
    passwordConfirm: ``,
    errorMessage: ``,
    disableSubmit: false
  }

  componentDidMount() {
    var query = this.state.query;
    if(globalHistory.location.search){
      query = parseQuery(globalHistory.location.search)
      if(query.email){
        this.setState({
          username: query.email
        })
      }
    }

  }
  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit(event) {
    event.preventDefault()
    var updateMessage = function(text){
      this.setState({
        errorMessage: text,
        disableSubmit: false,
      })
    }.bind(this)

    var redirect = function(){
      navigate('/verify?email='+this.state.username.toLowerCase())
    }.bind(this)

    this.setState({
      disableSubmit: true
    }, function(){
      handleRegister(this.state, function(err, user){
        if(err){
          updateMessage(err.message)
        }else{
          redirect()
        }
      })
    }.bind(this))
  }

  render() {
    isLoggedIn()
    .then(user =>{
      if(user){
        navigate('/')
      }
    })
    return (
      <Layout>
      <View title='Register for an Account'>
        <Form
          handleUpdate={e => this.handleUpdate(e)}
          handleSubmit={e => this.handleSubmit(e)}
          disableSubmit={this.state.disableSubmit}
        />
        <div>{this.state.errorMessage}</div>
      </View>
      </Layout>
    )
  }
}

export default Register
